@import '../../app-variable';
.prism-overridden {
  .lane-management-add-run {
    padding: 0 !important;
    .prism-radio>label {
      margin-top: 3px;
      margin-bottom: 3px
    }

    .search-seller-btn {
      align-items: center;
      display: flex;
      margin-top: 1.6em;
    }

    .lane-management-add-run-footer {
      background-color: var(--white);
      position: absolute;
      bottom: 0;
      width: calc(100% - 0.1rem);
    }

    .control-buttons {
      width: 100%;
      display: flex;
      padding-right: 0.1rem!important;
    }

    .col-form-label {
      font-size: $app-font-size;
    }

    .error-message {
      margin: 0 0 0.8rem 0 !important;
    }

    .col-form-label {
      font-size: $app-font-size !important;
    }

    input[type=text] {
      font-size: $app-font-size !important;
      padding: .2rem .4rem !important;
      height: $app-from-control-height
    }

    .prism-message--danger {
      padding: 0 !important;
      .prism-message__text {
        line-height: 1.2rem;
      }
    }

    .mx-2.prism-message--danger {
      margin: 0 0 0.8rem 0 !important;

      .prism-message__text {
        line-height: 1.2rem;
      }
    }

    .mx-2.prism-message--success {
      margin: 0 0 0.8rem 0 !important;
      .prism-message__text {
        line-height: 1.2rem;
      }
    }

    @media (max-width: 480px) {
      .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
      }
    }
  }

  .prism-sandbox.prism-sandbox .custom-modal {
    .modal-content {
      width: 420px !important;

    }

    .modal-body {
      overflow-x: hidden;
    }
  }
}


