@import '../../.././app-variable.scss';

.search-seller-panel {
    /** grey background for inactive content*/
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 9997 !important;

    .status-active {
        border-left: 10px solid #ffffff;
        padding-left: 0.5rem !important;
    }

    .status-inactive {
        border-left: 10px solid #FFC20E !important;
        padding-left: 0.5rem !important;
    }

    .status-unauth {
        border-left: 10px solid #C33A00 !important;
        padding-left: 0.5rem !important;
    }

    /**/
    .slide-out-content {
        width: 85%;
        min-width: 400px;
        .slide-out-body {
            width: 100%;
            margin-left: 7px;
            padding-left: 3px;
            max-height: unset !important;
            overflow-y: hidden !important;
            table {
                border-collapse: initial;
                tr td {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
                }
                td.border-0.status-unauth{
                    text-wrap: nowrap !important;
                }
                td.border-0.status-active{
                    border-left: 10px solid #ffffff !important;
                }
            }

            .prism-message {
                margin-left: 0em !important;
            }

            .dearler-search {
                border-radius: 4px !important;
            }
            
            .search-seller-header {
                display: flex;
                align-items: center;

                h1 {
                    margin-bottom: 0px !important;
                    margin-left: 10px;
                }
                button.btn.btn-outline.btn {
                    width: 28px;
                    font-size: 12px !important;
                    text-align: center;
                }
            }

            .state-align {
                padding-left: 9px !important;
            }

            .search-seller-table {
                height: 100% !important;
                overflow-y: auto !important;
                margin-top: 30px !important;
                max-height: calc(100% - 140px);

                .card-header {
                    padding-right: 0 !important;
                } 
            }

            .search-seller-item {
                margin-top: 30px;
                height: 100% !important;
                .row {
                    margin-right: 0 !important;
                }
                
            }
            .col-seller-number {
                flex: 20%;
                max-width: 20%;

                input {
                    width: calc(100% - 2rem);
                }
            }
        }
    }

    @media only screen and (min-width: 1168px) {
        .slide-out-content {
            width: 75%;
            .slide-out-body {
                width: 100%;
                max-height: unset !important;
                overflow-y: hidden !important;

                .search-seller-input-group {
                    .search-seller-name {
                        flex: 18%;
                        max-width: 18%;
                    }
                    .search-seller-gp-code {
                        flex: 18%;
                        max-width: 18%;
                    }
                    .search-seller-alt-code {
                        flex: 18%;
                        max-width: 18%;
                        white-space: nowrap;
                    }
                    .search-seller-btn {
                        flex: 25%;
                        max-width: 25%;
                    }
                }

                .search-seller-item {
                    .row {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 679px) {
        .slide-out-content {
            .slide-out-body {
                .search-seller-table {
                    table {
                        thead {
                            display: table-header-group;
                        }
                        tr {
                            display: table-row;
                            padding: 0.75rem 1.25rem;

                            td {
                                display: table-cell;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 680px) and (max-width: 1167px) {
        .slide-out-content {
            .slide-out-body {
                .search-seller-input-group {
                    .search-seller-name {
                        flex: 25%;
                        max-width: 25%;
                    }
                    .search-seller-gp-code {
                        flex: 25%;
                        max-width: 25%;
                    }
                    .search-seller-alt-code {
                        flex: 25%;
                        max-width: 25%;
                        white-space: nowrap;
                    }
                    .search-seller-btn {
                        flex: 25%;
                        max-width: 25%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {
        .slide-out-content {
            .slide-out-body {
                .search-seller-input-group {
                    input::placeholder {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 499px) {
        .slide-out-content {
            .slide-out-body {
                .search-seller-input-group {
                    input::placeholder {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
