@import '../../app-variable.scss';

.search-history-form {
    padding-top: 3.5px;
    background-color: var(--ash);
    font-size: $app-font-size;
    color: var(--navy);
    white-space: nowrap;

    .search-history-form-row-wrapper {
            width: 100%;
            margin-left: 13px;
            margin-right: 13px;
        .search-history-form-row {
            padding-top: 3.5px;
            padding-bottom: 3.5px;

            .search-history-form-lbl {
                display: flex;
                align-items: center;
                justify-content: left;
                padding-right: 0;
            }

            .search-dropdown {
                $dropdown-height: 32px;
                $dropdown-text-padding: 0.4rem 0.8rem !important;
                padding-left: 0 !important;
                color: var(--black) !important;

                .prism-select {
                    height: $dropdown-height;
                    flex-grow: 0;

                    .prism-select__option {
                        background-size: 0;
                        padding: $dropdown-text-padding;
                    }

                    .prism-select__menu-open {
                        padding: 0;
                    }

                    .prism-select__label {
                        font-size: $app-font-size;
                        padding-right: 0;
                    }

                    .prism-select__toggle {
                        background-size: $app-font-size;
                    }

                    .prism-select__option {
                        font-size: $app-font-size;
                    }

                    .prism-select__toggle {
                        font-size: $app-font-size;
                    }

                    .prism-select__menu-open {
                        margin-top: -8px;
                        max-height: 485px;
                        overflow-y: auto;
                    }
                }

                .form-control:focus {
                    box-shadow: none !important;
                }

                .prism-select__menu-heading {
                    font-size: $app-font-size;
                    padding: 0.4rem 0.8rem;
                }
            }

            .auction-dropdown {
                margin-left: 0.62rem !important;
                .prism-select-md {
                    width: 10rem;
                }
            }

            .saleyear-dropdown {
                .prism-select-md {
                    width: 10rem;
                }
            }

            .search-history-form-input {
                width: 13.8rem;
            }

            .sale-number-input {
                width: 6rem;
            }

            .search-history-form-group {
                gap: 6px !important;
                flex-grow: 3;
            }

            .request-creation-date-lbl {
                display: flex;
                align-items: center;
            }

            .request-sale-number-lbl {
                display: flex;
                align-items: end;
                margin-bottom: 0.4rem;
            }

            .search-history-form-calendar {
                width: 7.5rem;
            }
            .picker .react-datepicker__input-container .form-control.inset-right {
                padding-right: 2.2rem;
            }
            .date-picker, .search-history-form-group {
                margin: 0;
                flex-grow: 3;
                .col-form-label {
                    font-size: $app-font-size;
                }
            }

            .lane-number-input {
                width: 19.1rem;
            }

            .search-history-form-right-col {
                display: flex;
                justify-content: right;
            }

            .form-group {
                margin-top: 0!important;
                margin-bottom: 0!important;
            }

            .dis-in-block {
                display: inline-block;
            }

            .gray-lbl {
                color: var(--gray);
            }

            .search-history-btn {
                width: 5.5rem;
            }

            .pl-right-col {
                padding-right:0rem;
            }
            .m-g-from {
                margin-left: 0.9rem;
            }
            .btn-control {
                max-width: 30.8rem !important;
            }
            .btm-align-btn {
                padding-bottom: 3.5px;
            }
        }

    }
    
}

@media only screen and (max-width: 1919px) and (max-height: 800px) {
    .search-history-form {
        padding-top: 3.5px;

        .search-history-form-row-wrapper {
            .search-history-form-row {
                .search-history-form-lbl.col-md-2 {
                    max-width: 25%;
                    flex: 0 0 25%;
                    padding-left: 0;
                }

                .p-0.col-md-4 {
                    max-width: 33%;
                    flex: 0 0 33%;
                }

                .pl-2.pr-1.col-md-5 {
                    max-width: 44%;
                    flex: 0 0 44%;
                }

                .search-dropdown {
                    max-width: 75%;
                }

                .auction-dropdown {
                    margin-left: 0.62rem !important;
                    .prism-select-md {
                        width: 10rem;
                    }
                }

                .saleyear-dropdown {
                    .prism-select-md {
                        width: 10rem;
                    }
                }

                .search-history-form-input {
                    width: 13.8rem;
                }
        
                .sale-number-input {
                    width: 6rem;
                }

                .search-history-form-calendar {
                    width: 7.5rem;

                    .date-picker-input::placeholder {
                        font-size: 12px;
                    }
                }

                .picker .react-datepicker__input-container .form-control.inset-right {
                    padding-right: 2.2rem;
                }

                .date-picker-trigger.btn.input-group-action {
                    padding: 0;
                    margin-left: 4.7rem;
                    position: absolute;
                }
                
                .search-history-form-group {
                    gap: 3px !important;
                    flex-grow: 3;
                }

                .lane-number-input {
                    width: 18.5rem;
                }

                .pl-right-col {
                    padding-right:0.4rem;
                }

                .m-w-23 {
                    max-width: 23%;
                    flex: 0 0 23%;
                }

                .m-w-20 {
                    max-width: 20%;
                    flex: 0 0 20%;
                }

                .m-w-15 {
                    max-width: 15%;
                    flex: 0 0 15%
                }

                .m-w-65 {
                    max-width: 65%;
                    flex: 0 0 65%;
                }

                .m-w-70 {
                    max-width: 70%;
                }
                .m-g-from {
                    margin-left: 1.24rem;
                }
                .btn-control {
                    max-width: 25.2rem !important;
                }
            }
        }
    }
}
@media only screen and (max-width:1102px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .search-history-form-row {
                .padding-right-align{
                    padding-bottom: 7px;
                }
            }
        }
    }
}
@media only screen and (max-width:1014px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .search-history-form-row {
                .padding-right-align{
                    justify-content: unset !important;
                }
                .right-align-col{
                    justify-content: unset !important;
                }
            }
        }
    }
}
@media only screen and (max-width: 695px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .search-history-form-row {
                .padding-right-align{
                    padding-right: 3.2rem;
                    justify-content: unset !important;
                    padding-top: 7px;
                }
                .right-align-col{
                    justify-content: unset !important;
                }
            }
        }
    }
} 
@media only screen and (min-width: 961px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .error-message-align {
                padding: 0 !important;
            }
        }
    }
}
@media only screen and (max-width: 960px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .error-message-align {
                padding-left: 0 !important;
            }
        }
    }
}
@media only screen and (max-width: 679px){
    .search-history-form {
        .search-history-form-row-wrapper {
            .error-message-align {
                padding-left: 0 !important;
                padding-right: 0.8rem !important;
            }
        }
    }
}
@media only screen and (max-width: 435px) {
    .search-history-form {
        .search-history-form-row-wrapper  {
            margin-left: unset !important;
            margin-right: unset !important;
            .search-history-form-row {
                .lane-number-input {
                    width: 12.5rem !important;
                }
                .padding-right-align {
                    padding-right: 0rem !important;
                }
                .m-g-from {
                    margin-left: 0rem !important;
                }
                .right-align-col{
                    justify-content: unset !important;
                    flex-grow: 3;
                    gap: 1px !important;
                }
                .search-history-form-calendar {
                    width: 7.3rem !important;
                }
            }
        }
    }
}