@import '../../app-variable.scss';

.prism-overridden {

    .run-history-result-items {

        thead{
            position: sticky;
            top: 0;
        }
        .table {
            thead {
                tr {
                    :nth-child(-n+4) {
                        width: 6% !important;
                    }
                    :nth-child(6) {
                        width: 8% !important;
                    }
                    :nth-child(7) {
                        width: 10% !important;
                    }
                    :nth-child(8) {
                        width: 10% !important;
                    }
                    :nth-child(9) {
                        width: 10% !important;
                    }
                }
            }
            @media only screen and (max-width: 1168px) and (max-height: 800px) {
                thead {
                    tr {
                        :nth-child(-n+4) {
                            width: 5% !important;
                        }
                    }
                }
            }
            .td-hide-border {
                border-top: none !important;
                border-bottom: none !important;
                background-color:  #F6F6F6 !important;
                color: var(--slate) !important;
                padding-top: 2px;
                padding-bottom: 2px;
            }

            .td-hide-top-border {
                border-top: none !important;
                background-color:  #F6F6F6 !important;
                padding-top: 2px;
            }

            .td-hide-bottom-border {
                border-bottom: none !important;
                background-color:  #F6F6F6 !important;
                padding-bottom: 2px;
            }
            .tr-bg-gray {
                color: var(--slate) !important;
            }

            .see-all-request {
                color: #C33A00 !important;
            }
        }
    }
    .search-result-table {
        $dropdown-width: 80px;
        $dropdown-height: 32px;
        $dropdown-text-padding: 0.4rem 0.8rem !important;
        $dropdown-font-size: 14px;

        .row {
            flex-wrap: nowrap;
        }
        .search-result-table-file-export {

            span {
                width: inherit;
            }

            .font-size-lg {
                font-size: calc($dropdown-font-size + 2px);
            }

            .font-size-md {
                font-size: $dropdown-font-size;
            }

            th:last-child {
                padding-right: 0.75rem;
            }

            .row {
                gap: 8px !important;
            }
        }
        .prism-select {
            height: $dropdown-height !important;
            width: 90px;
            flex-grow: 0;
            .prism-select__toggle {
                font-size: $dropdown-font-size;
            }
            .prism-select__option {
                font-size: $dropdown-font-size;
            }
        }

        .col-lg-2 {
            width: 14rem;
        }
        .top-padding-sm{
            .modal-header-title{
                color: #003468
            }
        }
        
        @media only screen and (min-width: 1920px) {
            .align-seller-info{
                padding-left: 2.4em !important;
            }
        }
        @media only screen and (min-width: 590px) and (max-width: 679px) {
            .align-seller-info{
                padding-right: 10rem !important;
            }
        }
        @media only screen and (min-width: 680px) and (max-width: 820px) {
            .align-seller-info{
                padding-left: 1.6em !important;
            }
        }
        @media only screen and (min-width: 1169px) and (max-width: 1912px) {
            .align-seller-info{
                padding-left: 1.6em !important;
            }
        }
    }
    @media only screen and (max-width: 679px) {
        .table{
          thead {
            display: table-header-group;
          }
          tr {
            display: table-row;
          }
          td {
            text-align: left;
            display: table-cell;
          }

        }
      }
}
