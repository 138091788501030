@import '../../../app-variable';

.drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    font-size: $app-font-size;
    height: 100%;
    min-width: 400px;

    .drag-layer-item {
        border-top: 1px solid var(--smoke-babcbe, #babcbe);
        border-left: 1px solid var(--smoke-babcbe, #babcbe);
        border-right: 1px solid var(--smoke-babcbe, #babcbe);
        background-color: white;
    }
}

@media only screen and (max-width: 1168px) and (max-height: 800px) {
    .drag-layer {
        font-size: 12px;

        .assign-vehicle-item {
            .vehicle-title {
                font-size: 16px;
            }

            .vehicle-odometer {
                .chip {
                    padding: 0.4rem 0.4rem;
                }
            }

            .vehicle-disposition-code {
                font-size: 24px;
            }

            .mt-2.w-100.text-right {
                white-space: nowrap;
            }
        }
    }
}

body.drag-layer-item-dragging {
    * {
        cursor: grabbing !important;
        user-select: none;
    }
}
