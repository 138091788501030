.prism-overridden {
  .main-assign-inventory {
    height: calc(100vh - 6rem) !important;
    display: flex;
    flex-direction: column;

    .assign-inventory-message:has(.prism-message) {
      .prism-message {
        margin-bottom: 0rem !important;
      }
    }
    .row-vehicle-detail {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }
    .assign-inventory-detail-container-wrapper,
    .assign-inventory-detail-wrapper,
    .assign-inventory-container-col,
    .unassign-inventory-container-col,
    .assign-inventory-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .assigned-inventory, .unassigned-inventory {
        height: 100%;
      }
    }
  }
  @media  only screen and (max-width: 1146px) {
    .main-assign-inventory {
      height: calc(100vh - 6.2rem) !important;
    }
  }
  @media  only screen and (max-width: 1142px) {
    .main-assign-inventory {
      height: calc(100vh - 8.3rem) !important;
    }
  }
  @media  only screen and (max-width: 679px) {
    .main-assign-inventory {
      height: calc(100vh - 11rem) !important;
    }
  }
  @media  only screen and (max-width: 434px) {
    .main-assign-inventory {
      height: calc(100vh - 13rem) !important;
    }
  }
  @media  only screen and (max-height: 490px) {
    .main-assign-inventory {
      height: unset !important;
    }
  }
}