@import '../../app-variable';

.lane-management-create-request {

  .prism-checkbox {
    height: 16px !important;
    min-width: 16px !important;
  }
  .prism-radio>label {
    margin-top: 3px;
    margin-bottom: 3px
  }

  .btn-apply-filter {
    color: var(--black);
    background-color: var(--smoke);
  }

  .lane-management-create-request-footer {
    background-color: var(--white);
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.1rem);
  }

  .control-buttons {
    width: 100%;
    display: flex;
    padding-right: 0.1rem!important;
  }

  .group-btn-right {
    right: 0;
  }

  .btn-apply-filter:hover, .btn-apply-filter:focus {
    background-color: var(--slate);
    color: var(--black);
    border-color: var(--slate);
  }

  .checkbox-label {
    font-size: $app-font-size;
    color: var(--black);
  }

  .search-seller-btn {
    align-items: center;
    display: flex;
    margin-top: 1.6em;
  }

  .col-form-label {
    font-size: $app-font-size;
  }

  .cancel-btn {
    color: #005BA8;
  }

  .leave-info-message {
    color: #6A6A6A;
    font-size: 12px;
  }

  .textarea-counter__count {
    display: none;
  }

  .error-message {
    margin: 5px 0 0 0 !important;
  }

  .slide-out-content {
    max-width: 420px !important;
  }
  @media (max-width: 480px) {
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
  }
}
