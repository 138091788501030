@import '../../app-variable';

.assign-inventory-advanced-filters {
    .slide-out-content {
    width: 400px;
   }

  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    .slide-out-content .slide-out-body{
      max-height: calc(100% - 6rem) !important;
    }
  }

  @media only screen and (max-width: 1168px) and (max-height: 800px) {
    .slide-out-content .slide-out-body{
      height: 85%;
    }
  }

  .prism-checkbox {
    height: 16px !important;
    min-width: 16px !important;
  }
  .prism-radio>label {
    margin-top: 3px;
    margin-bottom: 3px
  }

  .btn-apply-filter {
    color: var(--black);
    background-color: var(--smoke);
  }

  .advanced-filter-footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.1rem);
    background-color: var(--white);
  }

  .control-buttons {
    width: 100%;
    display: flex;
    padding-right: 2rem;
  }

  .group-btn-right {
    right: 0;
  }

  .btn-apply-filter:hover, .btn-apply-filter:focus {
    background-color: var(--slate);
    color: var(--black);
    border-color: var(--slate);
  }

  .checkbox-label {
    font-size: $app-font-size;
    color: var(--black);
  }
}