.next-available-slide-out {
    width: 100% !important;
    .slide-out-content {
        width: 450px;
        padding: 15px !important;
        h1 {
            text-transform: unset !important;
        }
    }
    .slide-out-body {
        max-height: calc(100% - 51px) !important;
        overflow-y: hidden !important;
    }

    .next-available-panel {
        max-height: calc(100vh - 135px);
        overflow-y: auto;
        table {
            tbody {
                tr td {
                    border-bottom: 1px solid var(--smoke-babcbe, #babcbe);
                }
                tr:last-child td {
                    border-bottom: 2px solid var(--smoke-babcbe, #babcbe);
                }
            }
        }
        @media only screen and (max-width: 1168px) and (max-height: 800px) {
            .available-runs-table {
                padding-right: 3px;
            }
        }
        @media only screen and (max-width: 679px) {
            table {
                [data-heading]:before {
                    display: none;
                }
                thead {
                    display: table-header-group;
                }
                tr {
                    display: table-row;
                    padding: 0.75rem 1.25rem;
                    td {
                        display: table-cell;
                        text-align: left;
                    }
                }
            }
        }
    }
}
