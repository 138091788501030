@import '../../../app-variable.scss';

.prism-overridden {
  .prism-message__body span {
    line-height: 1.4rem;
    padding-right: 10px;
  }

  .prism-icon-svg {
    margin-top: 0.2rem !important;
  }

  .prism-message.alert .close {
    padding: 0.45rem !important;
  }

  .prism-message--success {
    background-color: #c0fb92
  }
}