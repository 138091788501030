.request-history-wrapper {
    height: calc(100vh - 165px);

    .table-sticky-wrapper:before {
        width: calc(100% - 2rem) !important;
    }
    @media (max-width: 479px) {
        .run-history-result-items {
            max-height:  calc(100vh - 425px);
            overflow-y: auto;
        }
    }
    @media (min-width: 479px) {
        .run-history-result-items {
            max-height: calc(100vh - 425px);
            overflow-y: auto;
        }
    }
}

@media (max-width: 1168px) {
    .request-history-wrapper {
        height: calc(100vh - 195px);
    }
}

@media (max-width: 1102px) {
    .request-history-wrapper {
        height: calc(100vh - 135px);
    }
}

@media (max-width: 1030px) {
    .request-history-wrapper {
        height: calc(100vh - 65px);
    }
}

@media (max-width: 680px) {
    .request-history-wrapper {
        height: 101vh;
    }
}

@media (max-height: 400px){
    .request-history-wrapper {
        height: 400px;
    }
}