@import '../../../../app-variable';

.trackVertical {
  right: 2px;
  bottom: 2px;
  width: 20px !important;
  top: 2px;
  border-radius: 3px;
  transition: all 200ms ease !important;
}


.trackHorizontal {
  display: none !important;
}

.renderView {
  position: absolute;
  inset: 0px;
  overflow: auto !important;
  margin-right: 0 !important;
}