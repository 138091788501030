@import '../../../app-variable.scss';

.prism-overridden {
  .default-modal {
    
    .modal-header {
      margin-bottom: 15px !important;
      .modal-header-title {
        font-size: 20px !important;
      }

      .modal-header--close {
        font-size: 20px !important;
      }
    }

    .modal-body {
      font-size: $app-font-size !important;
    }

    .modal-footer {
      padding-top: 1rem !important;

      .btn:not(.actions .actions-action) {
        font-size: 12px !important;
      }
    }

    .modal-content {
      padding: 20px !important;
      left: -50px !important; // Align dialog as center
    }

    .modal-header {
      cursor: grab !important;
    }
  }
}