
.search-result-table {
    .run-history-result-items {
        overflow-y: auto;
        .cursor-pointer {
            cursor: pointer;
        }
    }
}

.run-history-result-items {
    overflow-y: auto;
    .table {
        margin-bottom: 0 !important;
        tr {
            td {
                border-top: 0 !important
            }
        }
    }

    .table:last-child {
        border-bottom: 1px solid #babcbe !important;
    }
}
