@import '../../../app-variable';

.prism-overridden {
  .quick-search {
    .quick-search-label {
      font-size: $app-font-size;
      color: var(--navy);
    }

    .quick-search-input {
      box-shadow: none !important;
      width: 180px !important;
    }

    .form-group {
      margin-bottom: 0!important;
      margin-top: 0!important;

      .btn {
        right: 0.4rem !important;
      }
    }

    .disabled-icon-button {
      cursor: not-allowed !important;
    }

    .tooltip-sm {
      background-color: #ffffff;

      .card {
        width: 300px;
        color: #000000;
        border: none;

        .card-layout {
          .card-body {
            white-space: normal;

            .card-head {
              display: block;

              .card-title {
                font-size: calc($app-font-size + 1px);
                color: #003468
              }
            }
          }
        }

        .checkbox-label {
          font-size: calc($app-font-size + 1px);
          color: #000000;
        }

        .checkbox--custom input[type=checkbox] {
          margin: 8px 8px 8px -5px;
        }
      }
    }

    .prism-icon-cross {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #5a5a5a;
      font-size: 16px;
    }

    @media  only screen and (max-width: 1000px) {
      .quick-search-input {
        height: $app-from-control-height-small-screen;
        font-size: $app-font-size-small-screen;
      }
    }
    @media  only screen and (max-width: 600px) {
      .quick-search-input {
        width: 130px !important;
      }
    }
  }
  .new-feature-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000000;
    top:0;
    left:0;
    z-index: 2;
    opacity: 0.25;
  }
}
