@import 'src/app-variable';

.assign-inventory-sort-by {
  $dropdown-width-lg: 160px;
  $dropdown-width-sm: 100px;
  $dropdown-height: 32px;
  $dropdown-text-padding: 0.2rem 0.3rem !important;

  .sub-menu {
    color: var(--black)
  }

  .prism-select {
    flex-grow: 0;

    .prism-select__option {
      background-size: 0;
      padding: $dropdown-text-padding;
    }

    .prism-select-md {
      height: $dropdown-height;
    }

    .prism-select__menu-open {
      padding: 0;
    }

    .prism-select__label {
      font-size: $app-font-size;
      opacity: 1 !important
    }

    .prism-select__toggle {
      background-size: $app-font-size;
      height: $dropdown-height;
    }

    .prism-select__option {
      font-size: $app-font-size;
    }

    .prism-select__toggle {
      font-size: $app-font-size;
    }

    .prism-select__menu-open {
      margin-top: -8px;
    }

    .prism-select__menu-open {
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .prism-select {
    font-size: var(--app-font-size);
    height: 32px;
    width: 160px;

    .prism-select__option {
      font-size: var(--app-font-size);
    }
  }

  .disabled {
    pointer-events: none;
  }

  .invisible-arrow {
    visibility: hidden;
  }

  .toggle {
    border-radius: 50px;
    height: 32px;
    width: 56px;
    font-size: var(--app-font-size);

    .link-md {
      font-size: var(--app-font-size);
    }

    .icon {
      border-radius: 50%;
      padding: 5px;

      &.selected {
        color: var(--white);
      }
    }
  }

  .toggle-disable {
    cursor: not-allowed;
    border: 1px solid var(--smoke);
    background-color: var(--ash);

    .link {
      color: var(--smoke);
    }

    .icon {
      &.selected {
        background-color: var(--smoke);
      }
    }
  }

  .toggle-enable {
    cursor: pointer;
    border: 1px solid var(--navy);
    background-color: var(--white);

    .link {
      color: var(--secondary);
    }

    .icon {
      &.selected {
        background-color: var(--secondary);
        color: var(--white);
      }
    }
  }
  }

@media  only screen and (max-width: 1000px) {
  .assign-inventory-sort-by {
    .prism-select {
      height: $app-from-control-height-small-screen;
      .prism-select__label {
        font-size: $app-font-size-small-screen !important;
      }
      .prism-select__toggle {
        font-size: $app-font-size-small-screen;
      }
      .prism-select__option {
        height: $app-from-control-height-small-screen;
      }
      .prism-select__menu-open {
        margin-top: -12px;
      }
    }
  }
}

@media  only screen and (max-width: 600px) {
  .assign-inventory-sort-by {
    .prism-select {
      width: 130px !important;
    }
  }
}