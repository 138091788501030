@import '../../app-variable.scss';

.lane-vehicle-item {
    padding: 4px 8px 4px 8px;
    border-bottom: 1px solid var(--smoke-babcbe, #babcbe);

    .vehicle-title {
        font-weight: 700;
        font-size: 16px;
    }

    .vehicle-metric{
        width: 80px;
        height: 80px;
        .CircularProgressbar-path {
          stroke: #9fe695;
        }
        .CircularProgressbar-trail {
          stroke: #c0bdbd;
        ;
        }
        .separator{
          background: #fff;
          width: 4px;
          height: 10%
        }
        .vehicle-sale-percent{
            font-size: 14px;
            color: #005BA8;
        }
    }

    @media only screen and (max-width: 1050px) {
        .vehicle-odometer {
            white-space: normal !important;
        }
    }
    @media only screen and (max-width: 1050px) {
        .vehicle-metric-wrapper {
            margin-right: 100%;
        }
        .arb-bought-wrapper {
            margin-left: 5%;
        }
        .vehicle-odometer {
            margin-right:5%;
        }
        .no-sale-sold-wrapper {
            margin-right: 10%;
            margin-left: 10%;
        }
    }
    @media only screen and (max-width: 679px) {
        .arb-bought-wrapper {
            margin-left: 0;
        }
        .no-sale-sold-wrapper {
            margin-left: 0;
        }
    }

    .vehicle-odometer {
        white-space: nowrap;
    }

    .modify-content{
        font-size: 14px;
        font-weight: 700;
    }

    .rans-served{
        font-size: 32px;
        font-weight: 700;

        .run-count{
            justify-content: right;
        }
        .plus-minus-btn{
            font-size: 12px !important;
            width: 1.75rem !important;
            border-radius: 4px !important;
        }
        .prism-icon-plus{
            &:before {
                font-size: 12px;
            }
        }
        .prism-icon-minus3{
            &:before {
                font-size: 12px;
            }
        }
    }

    .addt-cursor {
        cursor: not-allowed;
    }

    .addt-request{
        font-size: 12px !important;
        color: #005BA8;
        display: flex;
        align-items: flex-end;
        justify-content: right;
    }

    .addt-request-disable {
        color: var(--gray) !important;
        pointer-events: none;
    }

    .no-addt-request {
        font-size: 12px !important;
        font-weight: normal;
    }
    
    .addt-request-summary {
        font-size: $app-font-size;
        font-weight: normal;
    }

    .vehicle-adj-mmr-label {
        word-break: break-all;
    }
}

.bd-t {
    border-top: 0.5px solid var(--smoke-babcbe, #babcbe);
}

.h-7 {
    height: 8rem;
}

.sent-to-as400 {
    background-color: #dddcdc !important;
}

.mapped-requests, .unmapped-requests {
    .renderView > div > :last-child > div > .lane-vehicle-item + .bd-t {
        border-top: 0;
    }
}

@media only screen and (max-width: 689px) {
    .addt-request-summary {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 1080px) {
    .mr-1-rem {
      margin-left: 0.4rem!important;
    }
}
@media only screen and (max-width: 1168px) and (max-height: 800px) {
    .mr-1-rem {
      margin-left: 0.1rem!important;
    }
    .pl-1-rem {
      padding-left: 1.3rem!important;
    }
    .no-addt-request{
      width: 10em;
    }
}