@import './app-variable.scss';

#root {
    padding-bottom: 10px;

    .dsp-n {
        display: none
    }
}

.prism-overridden {    
    .prism-anchor {
        font-size: $app-font-size;
    }
    .actions {
        .actions-action {
            &.btn {
                font-size: $app-font-size;
            }
        }
        .actions-icon {
            width: $app-font-size;
            height: $app-font-size;
        }
    }
    .btn {
        &:not(.actions .actions-action) {
            font-size: $app-font-size;
            height: $app-btn-height;
            min-height: $app-btn-height;
            max-height: $app-btn-height;
            padding: $app-btn-padding;
            text-transform: none;
        }
    }
    .table {
        font-size: $app-font-size;
        th {
            font-size: $app-font-size;
            padding: $app-table-th-padding;
            &:first-child {
                padding: $app-table-th-padding;
            }
        }
        td {
            font-size: $app-font-size;
            padding: $app-table-td-padding;
            &:first-child {
                padding: $app-table-td-padding;
            }
            &:last-child {
                padding: $app-table-td-padding;
            }
        }
        .table-data-cell {
            &:before {
                font-size: $app-font-size;
                line-height: inherit;
            }
        }
    }
    .card {
        font-size: $app-font-size;
        .card-body {
            font-size: $app-font-size;
            margin: $app-card-body-margin;
        }
    }
    .card-header{
        font-size: $app-font-size;
        background: #fff;
    }
    .chip {
        font-size: $app-font-size;
        margin: 0rem;
        border: $app-chip-border;
        min-width: $app-chip-min-width;
        min-height: $app-chip-min-height;
        height: $app-chip-min-height;
    }
    .prism-message {
        &.alert {
            .close {
                padding: 0.6rem;
                &:before {
                    font-size: $app-font-size;
                }
            }
            .prism-message__body {
                display: $app-prism-message-body-display;
                align-items: $app-prism-message-body-align-items;
                padding-left: 24px;
                .prism-icon-svg {
                    margin-top: 0.3rem;
                }
                .prism-message__text {
                    font-size: $app-font-size;
                }
            }
        }
    }

    .prism-message--success button.close {
        visibility: visible;
        margin-top: -3px
    }
    
    .slide-out-content {
        font-size: $app-font-size;
        padding: $app-slideout-padding;
        h1 {
            font-size: $app-slideout-header-size;
            margin-bottom: 1rem;
        }
        .slide-out-close {
            padding: 0px;
            width: 3rem;
            height: 3rem;
            svg {
                width: $app-slideout-header-size;
                height: $app-slideout-header-size;
            }
        }
        .slide-out-body {
            max-height: $app-slideout-body-max-height;
        }
    }

    .form-control {
        font-size: $app-font-size;
        padding: $app-from-control-padding;
        line-height: $app-from-control-line-height;
        height: $app-from-control-height;
    }

    // Custom
    @each $cursor-style in $cursor-styles {
        .cursor-#{$cursor-style} {
            cursor: $cursor-style;
        }
    }
    .font-size-common {
        font-size: $app-font-size;
    }

    .highlight {
        animation: background-fade 3s both;
    }

    @keyframes background-fade {
        0% {
            background:#FFFFFF;
        }
        50% {
            background:#FFFF99;
        }
        100% {
            background:#FFFFFF;
        }
    }

    .dragable {
        cursor: grab;
    }

    .table-sticky-wrapper:before {
        content: "";
        position: absolute;
        margin-top: -1px;
        border: 1px solid #ffffff;
        width: 100%;
        width: -webkit-fill-available;
        width: fill-available;
        width: -moz-available;
    }
}
