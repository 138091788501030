.remaining-inventory {
    .remaining-inventory-items-wrapper .remaining-inventory-items .remaining-inventory-item-info .row {
        padding-left: 0;
        padding-right: 0;
    }

    .status-active {
        border-left: 10px solid #ffffff;
        padding-left: 0.5rem !important;
    }

    .status-inactive {
        border-left: 10px solid #FFC20E !important;
        padding-left: 0.5rem !important;
    }

    .status-unauth {
        border-left: 10px solid #C33A00 !important;
        padding-left: 0.5rem !important;
    }

    .vehicle-count {
        i {
            color: var(--secondary);
        }
    }

    @media (max-width: 479px) {
        .remaining-inventory-items {
            max-height: max-content;
            overflow-y: auto;
        }
    }
    @media only screen and (max-width: 679px) {
        .remaining-inventory-items {
            .slide-out-body {
                .vehicle-detail-content-wrapper {
                    .vehicle-detail-content {
                        .col, [class*=col-] {
                            padding-left: .8rem;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 499px) {
        .remaining-inventory-items {
            .assign-inventory-item {
                padding-left: 0.8rem !important;
            }
        }
    }
}