@import '../../app-variable.scss';

.vehicle-alert-icon {
    display: flex;
    width: 1.4rem;
    height: 1.2rem;
    align-items: center;
    justify-content: center;
    margin-right: .6rem !important;
}

@media  only screen and (max-width: 1000px) {
    .vehicle-alert-icon {
        font-size: $app-font-size-small-screen;
        width: 1.2rem;
        height: 1rem;
        margin-right: .4rem !important;
    }
}