@import '../../../app-variable.scss';

@media (min-height: 800px) {
  .list{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

@media (max-height: 800px) {
  .list{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}