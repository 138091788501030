@import '../../../app-variable';

.advanced-filters {
  $dropdown-width-lg: 160px;
  $dropdown-width-sm: 100px;
  $dropdown-height: 32px;
  $dropdown-text-padding: 0.4rem 0.8rem !important;

  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    .slide-out-content {
      width: 22%;
    }
    .slide-out-content .slide-out-body{
      height: 88%;
      height: 87%;
    }
  }

  @media only screen and (max-width: 1620px) and (max-height: 800px) {
    .slide-out-content {
      width: 25%;
    }
  }

  @media only screen and (max-width: 1550px) and (max-height: 800px) {
    .slide-out-content {
      width: 33%;
    }
  }

  @media only screen and (max-width: 1168px) and (max-height: 800px) {
    .slide-out-content {
      width: 36%;
    }
    .slide-out-content .slide-out-body{
      height: 85%;
    }
  }

  .prism-checkbox {
    height: 16px !important;
    min-width: 16px !important;
  }

  .prism-radio input {
    height: 16px !important;
    min-width: 16px !important;
    width: 16px !important;
  }

  .prism-radio {
    color: var(--black);
  }

  .checkbox-label {
    font-size: $app-font-size;
    color: var(--black);

  }

  .prism-radio {
    .itemCol {
      font-size: $app-font-size;
    }
  }

  .btn-apply-filter {
    color: var(--black);
    background-color: var(--smoke);
  }

  .advanced-filter-footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.1rem);
    background-color: var(--white);
  }

  .control-buttons {
    width: 100%;
    display: flex;
    padding-right: 2.3rem!important;
  }

  .prism-select-lg {
    width: $dropdown-width-lg;

  }

  .prism-select-sm {
    width: $dropdown-width-sm;
  }

  .prism-select {
    flex-grow: 0;

    .prism-select__option {
      background-size: 0;
      padding: $dropdown-text-padding;
    }

    .prism-select-md {
      height: $dropdown-height;
    }

    .prism-select__menu-open {
      padding: 0;
    }

    .prism-select__label {
      font-size: $app-font-size;
    }

    .prism-select__toggle {
      background-size: $app-font-size;
      height: $dropdown-height;
    }

    .prism-select__option {
      font-size: $app-font-size;
    }

    .prism-select__toggle {
      font-size: $app-font-size;
    }

    .prism-select__menu-open {
      margin-top: -8px;
    }
  }

  .text-area__textarea {
    width: 350px;
    resize: none;
  }

  .textarea-counter__count {
    display: none;
  }

  .group-btn-right {
    right: 0;
  }
  .btn-apply-filter:hover, .btn-apply-filter:focus {
    background-color: var(--slate);
    color: var(--black);
    border-color: var(--slate);
  }

}