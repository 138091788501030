@import '../../app-variable.scss';
  .lane-management-detail-container {
    font-size: $app-font-size;

    .lane-management-header {
      background-color: $app-menu-background-color;
      padding: $app-menu-padding-y/2;
      color: $app-menu-foreign-color;
      margin: auto;
      width: 100%;
    }

    .lane-management-list {
      background-color: #dddcdc;
      overflow-y: auto;
      overflow-x: hidden;
      .chip-light {
        padding: .4rem .3rem;
      }
    }
    @media  only screen and (max-width: 1000px) {
      .lane-management-list {
        font-size: $app-font-size-small-screen;
        .chip {
          font-size: $app-font-size-small-screen;
          min-width: 1rem;
          min-height: 1.2rem;
          height: 1.2rem;
        }
        .vehicle-title {
          font-size: calc($app-font-size-small-screen + 2px);
        }
        .vehicle-disposition-code {
          font-size: 17px;
        }
      }

      .lane-management-header, .sub-menu {
        div {
          font-size: $app-font-size-small-screen !important;
        }
        button {
          font-size: $app-font-size-small-screen !important;
          min-height: $app-from-control-height-small-screen;
          max-height: $app-from-control-height-small-screen;
        }
      }
    }
}