@import '../../../app-variable.scss';

.prism-overridden {
    .menu-bar {
        background-color: $app-menu-bar-background-color;
        .menu-group {
            padding: $app-menu-padding-y $app-menu-padding-x;
            overflow-x: auto;
            .menu-item {
                cursor: pointer;
            }

            a.link-light span {
                color: white;

                &:hover {
                    color: var(--gold);
                }
            }
        }
        .actions {
            .actions-action {
                &.btn {
                    color: $app-menu-foreign-color;
                }
            }
            .actions-icon {
                color: $app-menu-foreign-color;
            }
        }
        .tab-container {
            margin-top: 5px;
            .tab:before{
                top: 33px;
            }
            .nav-tabs {
                flex-wrap: wrap;
                li.nav-item {
                    margin-bottom: 0.2em;
                }
            }
        }
        .tab-content{
            display: none;
        }
        .show-next-available-label {
            font-weight: normal;
            cursor: pointer;
        }
    }
    .prism-sandbox.prism-sandbox {
        .actions-panel {
            &.dropdown-menu {
                padding: $app-sub-menu-padding;
                .actions-action {
                    padding: $app-sub-item-padding;
                    min-height: $app-sub-item-min-height;
                    &.btn {
                        font-size: $app-font-size;
                        box-shadow: none
                    }
                }
            }
        }
    }
}
