.error-container{
  padding: 6rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-code{
    font-size: 120px;
    font-weight: 700;
    color: #003468;
    line-height: 75px;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .error-label{
    font-size: 26px;
    font-weight: 700;
    color: #003468;
    margin-bottom: 24px;
  }

  .error-content{
    text-align: center;
    font-size: 16px;
  }

  .error-btn-back{
    font-weight: 700;
    font-size: 16px !important;
    padding: 8px 16px;
    background: #FFC20E;
    border-radius: 4px !important;
    color: #001B35;
    border: none;
  }
}