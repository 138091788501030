@import '../../app-variable.scss';

.prism-overridden {
    .assign-inventory-detail-wrapper {
        height: fit-content;

        .assign-inventory-container {
            background-color: $app-menu-background-color;
            font-size: $app-font-size;

            .assigned-inventory-header {
                padding: $app-menu-padding-y;
                color: $app-menu-foreign-color;
            }

            .unassigned-inventory-header {
                color: $app-menu-foreign-color;
                padding: 0.35rem;

                .header-content {
                    padding-top: $app-menu-padding-y / 2;
                }
            }

            .sub-menu {
                padding-bottom: 2px;
                background-color: var(--ash);
                .fix-content {
                    max-width: fit-content;
                }

                label {
                    font-size: $app-font-size;
                    color: var(--navy);
                }
                @media (max-width: 679px) {
                    .row {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }
                }
            }

            .sub-menu {
                .checkbox-all {
                    .checkbox--custom {
                        padding-left: 19px;
                    }

                    .checkbox--custom input[type=checkbox] {
                        margin: 8px;
                    }
                }

                .quick-search {
                    padding-top: 0.5px !important;
                    padding-bottom: 0.5px !important;
                }

                .sort-by {
                    padding-bottom: 0.05rem;
                    .select-sort {
                        margin: 2px 12px 2px auto;

                    }
                    .hidden-select {
                        display: none;
                    }
                    .sort-items {
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        margin-left: 0.2rem;
                        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                        width: -moz-available;          /* WebKit-based browsers will ignore this. */
                    }
                    .sort-item {
                        margin: .33rem 0.2rem 0 0;
                        padding-top: .15rem;
                        .prism-icon-cross {
                            font-size: 10px;
                            color: var(--saffron);
                            font-weight: normal;
                            cursor: pointer;
                        }
                    }
                }

                .check-all-vehicles {
                    margin-left: 2px;
                    margin-top: 8px !important
                }
            }

            .sub-menu-footer {
                background-color: $app-menu-foreign-color;

                input.starting-run-number {
                    width: 3rem;
                    margin-right: 5px;
                }

                .checkbox--custom .checkbox-label {
                    margin-right: 5px;
                }
            }

            .unassigned-footer {
                span {
                    color: var(--navy);
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                }
            }

            .chip-light {
                padding: .4rem .3rem;
                color: black;
                cursor: pointer;
                .prism-icon-svg {
                    margin-top: 0 !important;
                    width: 14px;
                    height: 14px;
                }
                .chip-icon-right {
                    margin-left: 0.2rem;
                    font-size: 14px;
                }
            }

            .assign-inventory-draggable {
                background-color: var(--ash);
                overflow-x: hidden;
                overflow-y: hidden;
            }
        }

        .dis-inl-flex {
            display: inline-flex;
        }
        @media  only screen and (max-width: 1000px) {
            .assign-inventory-container {
                font-size: $app-font-size-small-screen;
                .chip {
                    font-size: $app-font-size-small-screen;
                    min-width: 1rem;
                    min-height: 1.2rem;
                    height: 1.2rem;
                }
                .vehicle-title {
                    font-size: 14px;
                }
                .btn:not(.actions .actions-action) {
                    font-size: $app-font-size-small-screen;
                    white-space: nowrap;
                    min-height: $app-from-control-height-small-screen;
                    max-height: $app-from-control-height-small-screen;
                }
                .starting-run-label {
                    min-width: fit-content;
                }
                .starting-run-number {
                    height: $app-from-control-height-small-screen
                }
                .sub-menu {
                    .check-all-vehicles {
                        margin-top: 5px !important
                    }
                    label {
                        font-size: $app-font-size-small-screen;
                    }
                }
                .vehicle-disposition-code {
                    font-size: 17px;
                }
            }
        }
        @media  only screen and (max-width: 679px) {
            .row {
                padding-right: 0 !important;
            }

            .sub-menu-footer {
                .pl-8r {
                    margin-left: 0.4rem !important;
                }
            }

        }
        @media  only screen and (max-width: 599px) {
            .sub-menu-footer {
                .mg-sm {
                    margin-left: 0.4rem !important;
                    margin-right: 0.4rem !important;
                }
            }

        }
    }
    .checkbox--custom input[type=checkbox] {
        min-width: 15px;
        height: 15px;
    }
}