@import '../../.././app-variable.scss';

html:has(.vehicle-vcf-edit) {
    overflow-y: hidden;
}

.vehicle-vcf-edit {
    $dropdown-width-lg: 160px;
    $dropdown-width-sm: 100px;
    $dropdown-height: 32px;
    $dropdown-text-padding: 0.4rem 0.4rem !important;

    .slide-out-content {
        width: 50%;
        min-width: 380px;
    }

    .slide-out-body {
        max-height: calc(100% - 7.3rem)!important;
        width: 100%;
        padding-bottom: 3.2rem;
    }

    .col-form-label {
        font-size: $app-font-size;
        color: black;
        opacity: 0.5;
        margin-bottom: 0;
    }

    .col-dropdown-status .prism-select {
        width: 100%;
    }

    .vehicle-vcf-item {
        padding: 15px;
        background: #D9D9D980;
        border: 0.5px solid var(--smoke) !important;
    }

    .readonly-text {
        border-bottom: 1px solid var(--smoke) !important;
        color: black !important;
        opacity: 0.5 !important;
    }

    .update-vcf-col {
        text-align: end;
    }

    .update-vcf-footer {
        position: absolute;
        bottom: 0;
        right: 15px;
        width: auto;
        background: white;
    }

    .error-message {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .prism-select {
        height: $dropdown-height;
        flex-grow: 0;

        .prism-select__option {
            background-size: 0;
            padding: $dropdown-text-padding;
        }

        .prism-select__label {
            font-size: $app-font-size;
            padding-right: 0;
        }

        .prism-select__toggle {
            background-size: $app-font-size;
        }

        .prism-select__option {
            font-size: $app-font-size;
        }

        .prism-select__toggle {
            font-size: $app-font-size;
        }

        .prism-select__menu-open {
            padding: 0;
            margin-top: -8px;
            max-height: 320px;
            overflow-y: auto;
        }

        .prism-select__menu-heading {
            display: none;
        }

    }

}

