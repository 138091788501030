@import '../../../app-variable.scss';

.prism-overridden {
  .prism-sandbox.prism-sandbox .modal.fade .modal-dialog {
   transition: transform 0.01s linear !important;
  }
  .vehicle-notes {
    width: 460px !important;
    cursor: grab !important;
    resize: none;
    .modal-header {
      .modal-header-title {
        font-size: 20px !important;
      }

      .modal-header--close {
        font-size: 20px !important;
      }
    }

    .list-notes {
      max-height: 290px !important;
      margin-left: 0 !important;
      cursor: text;
    }

    .modal-body {
      width: 430px !important;
    }
  }
  @media only screen and (max-width: 499px){
    .vehicle-notes {
      width: 390px !important;
      .modal-body {
        width: 360px !important;
      }
    }
  }
  @media screen and (max-width: 481px) {
    .modal-content {
      margin-top: 80px !important;
      .modal-header {
        background-color: var(--white) !important;
        min-height: unset !important;
        margin-bottom: 15px !important;
        padding-left: 0 !important;
        position: static !important;
        .modal-header-title {
          color: var(--navy) !important;
        }
        button.close {
          top: 0 !important;
          transform: none !important;
        }
        .icon {
          color: var(--charcoal) !important;
        }
      }
      .modal-body {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

}