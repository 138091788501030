@import '../../app-variable.scss';

.lane-management-request-board-wrapper {
        height: calc(100vh - 180px);
        @media (max-width: 1085px) {
            height: fit-content;
        }

        .row {
            @media (max-width: 679px) {
                .col-md-6 {
                    width:50%;
                }
            }
        }

        .lane-management-container {
            background-color: $app-menu-background-color;
            font-size: $app-font-size;

            .h-10 {
                height: 10rem;
            }

            .lane-management-header {
                color: $app-menu-foreign-color;
                padding: $app-menu-padding-y / 2;

                .header-content {
                    padding-top: $app-menu-padding-y / 2;
                }

                .chip {
                    margin-top: -0.2rem;
                }

                .run-request-wrapper {
                    white-space: nowrap;
                }

                @media (max-width: 550px) {
                    .run-request-wrapper {
                        white-space: normal;
                    }
                }
                @media (max-width: 566px) {
                    .run-reserve-wrapper {
                        white-space: normal;
                    }
                }

                @media (max-width: 959px) {
                    .chip {
                        margin-left: 3%;
                    }
                    .col-md-4 {
                        max-width: 34% !important;
                        margin-top:1%;
                    }
                }

                @media (max-width: 915px) {
                    .text-right.pl-0.px-0.col-md-4 {
                        padding-top: 2%;
                    }
                }

                .create-request-btn {
                    white-space: nowrap;
                }
            }

            .sub-menu {
                background-color: $app-menu-foreign-color;
            }
            .table-bordered {
                .btn {
                    white-space: nowrap;
                }
                @media (max-width: 1087px) {
                    .save-draft-btn {
                        margin-left: 0 !important;
                    }
                    .btn {
                        margin-top:2px;
                    }
                }
                @media (max-width: 960px) {
                    .send-to-as400-col, .revert-button-col {
                        text-align: left !important;
                    }
                }
                @media (max-width: 920px) {
                     .revert-button-col {
                        margin-left: 1%;
                    }
                }
                @media (max-width: 871px) {
                    .col-md-2 {
                        max-width: 20% !important;
                    }
                }
                @media (max-width: 550px) {
                    .col-md-2 {
                        max-width: none !important;
                    }
                }

            }

            .mt-05 {
                margin-top: 0.2rem !important;
            }

            .mb-n05 {
                margin-top: -0.2rem !important;
            }

            .pt-pb-05 {
                padding-top: 12px !important;
                padding-bottom: 9px !important;
            }

            .btn:focus {
                box-shadow: 0 0 0 0 white !important;
            }

            .lang-management-draggable {
                background-color: var(--ash);
                overflow-y: hidden;
                overflow-x: hidden;
            }

            .comments-input {
                font-size: 12px !important;
            }

            .no-seller-comments {
                position: absolute;
                bottom: 0;
            }

            .w-40 {
                width: 36px;
                display: inline-block;
            }

            .mt-1-rem {
                margin-top: 1.4rem !important;
            }

            .chip {
                padding: .4rem .4rem !important;
            }
        }
        @media (max-width: 960px) {
            .assign-inventory-wrapper {
                padding-right: 0 !important;
            }
            .unassign-inventory-wrapper {
                padding-left: 0 !important;
            }
            .lane-management-container {
                width: 97%
            }
        }
}