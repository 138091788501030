@import '../../app-variable';

.assign-vehicle-item {
    padding: 4px 8px 4px 8px;
    //padding: 8px;
    border-bottom: 1px solid var(--smoke-babcbe, #babcbe);

    .vehicle-title {
        font-size: 15px;
        word-break: break-word;
        font-weight: 500;
        vertical-align: text-bottom;

    }
    .vehicle-disposition-code {
        font-size: 18px;
    }
    .vehicle-odometer {
        margin-top: 0.2rem;
        word-break: break-word;
        .chip {
            margin-right: 0.2rem !important;
            cursor: grab;
        }
    }
    .vehicle-adj-mmr {
        word-break: break-word;
        .vehicle-adj-mmr-label {
            word-break: break-all;
        }
    }
    .assign-vehicle-checkbox {
        margin: 6px !important;
    }
    .left-align {
        padding-left: 0 !important;
    }
    .align-center{
        display: flex;
        align-items: center;
    }

    .btn-remove {
        display: flex;
        justify-content: right;
    }

    .vehicle-vin {
        max-width: fit-content;
    }
    .seller-info {
        font-weight: lighter;
    }
    .pl-06r {
        padding-left: 0.6rem !important;
    }
    .pr-06r {
        padding-right: 0.6rem !important;
    }
    .pl-02r {
        padding-left: 0.2rem !important;
    }

    .prism-message__text {
        font-size: 13px !important;
        line-height: 1.2rem !important;
    }

    .prism-message {
        margin-bottom: 0.3rem !important;
        margin-top: 0.15rem !important;
        margin-right: 1rem !important;
    }

    .close {
        font-size: 1.4rem !important;
        padding-top: 0.4rem !important;
    }
    .prism-icon-svg {
        margin-top: 0.1rem !important;
    }
    .vin-work-order-group {
        .col-md-2 {
            max-width: 100%;
        }
    }
    .check-box-title-vehicle {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .current-ran {
        font-size: 1.2em
    }
    @media (max-width: 679px) {
        .row {
            padding-left: .2rem;
            padding-right: .2rem;
        }
        .search-seller-input-group {
            .row {
                padding-left: .4rem !important;
                padding-right: .4rem !important;
            }
        }
    }

    @media (max-width: 610px) {
        .vehicle-vin { // wrap text VIN
            overflow-wrap: break-word;
        }
    }
    @media (max-width: 1000px) {
        .prism-message__body {
            .prism-icon-svg {
                height: 14px;
            }
            .prism-message__text {
                font-size: $app-font-size-small-screen;
                line-height: 1rem !important;
            }
        }

    }

}

.bg-cerulean {
    background-color: #AFECFA !important;
    
}

.bg-smoke {
    background-color: #F9F9F9;
}

//Define this class to avoid use class bg-white from prism
.bg-wht {
    background-color: #FFF;
}

.bg-dropped {
    background-color: #E3FAD1;
}

.bg-sent-to-as400 {
    background-color: #dddcdc;
}

.highlight-vehicle {
    animation: background-fade 3s both;

}

@keyframes background-fade {
    0% {
        background:#FFFFFF;
    }
    50% {
        background:#FFFF99;
    }
    100% {
        background:#FFFFFF;
    }
}