// Global
$app-font-size: 14px;

// Button
$app-btn-padding: 0.5rem;
$app-btn-height: 32px;

// Table
$app-table-th-padding: 0.5rem;
$app-table-td-padding: 0.5rem;

// Card
$app-card-body-margin: 0.5rem;

// Chip
$app-chip-min-width: 1.5rem;
$app-chip-min-height: 1.5rem;
$app-chip-border: 1px solid #babcbe;

// Slideout
$app-slideout-padding: 1em;
$app-slideout-header-size: 30px;
$app-slideout-body-max-height: calc(100% - 40px);

// Form control
$app-from-control-padding: 0.2rem 0.4rem;
$app-from-control-line-height: 1;
$app-from-control-height: 32px;

// Prism Message
$app-prism-message-body-display: flex;
$app-prism-message-body-align-items: center;

// Use for Menu Bar only
$app-menu-padding-x: 0rem;
$app-menu-padding-y: 0.8rem;
$app-menu-foreign-color: var(--white);
$app-menu-background-color: var(--navy);
$app-sub-menu-padding: 0px;
$app-sub-item-padding: 8px;
$app-sub-item-min-height: 24px;
$app-menu-bar-background-color: #4A4A4A;

// Custom
$cursor-styles: auto, pointer;

// Size for mall screens (<1000px)
$app-font-size-small-screen: 12px;
$app-from-control-height-small-screen: 28px;