@import '../../app-variable';

#wbHeader .ewb-menu--nav .menu__item--nav.mui-header__utilities.user-actions #ewb-user__name,#ewb-help-header {
    font-size: $app-font-size;
}

#wbFooter {
    font-family: Arial, Helvetica, sans-serif;
}

#wbFooterUl .footer__item--nav a.footer__link--nav {
    color: #1952ce;
    
    &:visited {
        color: purple;
    }
}

#wbFooterCopyright {
    margin-block-end: 1em;
}
.menu__sub--header {
    padding: 0.625rem 0 4px 0.625rem !important;
}

#wbHeader .menu--sub--nav .menu__item--text {
    padding: 0 0.625rem !important;
}

#wbHeader .mui-multiselect__list--master .mui-item-list__container {
    height: 6.5625rem !important;
    padding: 0 0.625rem !important;
}

#wbHeader .mui-multiselect__list--master .mui-item-list__container .mui-item-list__items .mui-item-list {
    padding: 0.3125rem 0 0 !important;
}

#ewb-header__snowModalForm {
    label {
        font-size: $app-font-size !important;
    }

    input {
        min-height: 0 !important;
    }
}

#ewb-header__snow-problemDescription {
    vertical-align: unset !important;
}

#ewb-header__snowModalCancel, #ewb-header__snowModalSubmit, #ewb-header__snowActionClose{
    font-size: 18px !important;
    min-height: 0 !important;
}

