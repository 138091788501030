@import '../../../app-variable.scss';
.auction-code-popup {

    .modal-header {
        font-size: 20px !important;
    }

    @media only screen and (max-width: 480.90px) {
        .modal-header {
            min-height: 0 !important;
            margin-bottom: 9%;
        }
    }

    .modal-body {
        overflow: hidden;

        .prism-message.prism-message--danger {
            padding: 0 !important;
            margin: 0 !important;
            background: unset;
            color: var(--scarlet);
        }

        .auction-code-lbl {
            font-size: $app-font-size;
            padding-right: 0px !important;
            display: flex;
            align-items: center;
            justify-content: left;
            color: var(--navy-003468, #003468);
        }
    
        .pl-0 {
            padding-left: 0;
        }
    
        input {
            font-size: $app-font-size !important;
            padding: 0.5rem !important;
            height: 2.1rem !important;
        }
        
        .auction-btn {
            margin-top: 1rem;
        }
        
        .btn-cancel-wrapper {
            justify-content: right;
            display: flex;
    
            .btn-cancel {
                margin-right: 1rem;
            }
        }
    
        .btn-change {
            display: flex;
            justify-content: right;
        }
    }
    
}
