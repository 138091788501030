@import '../../app-variable.scss';
.prism-overridden {

  .lane-management-wrapper {
    height: calc(100vh - 6rem) !important;
    display: flex;
    flex-direction: column;

    .lane-management-messages:has(.prism-message) {
      .prism-message {
        margin-bottom: 0rem !important;
      }
    }

    .row-lane-management-request-board {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }

    .lane-management-detail-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 70%;

      .lane-management-list {
        overflow-y: auto;
      }
    }

    .lane-management-request-board-wrapper,
    .lane-management-container,
    .assign-inventory-wrapper,
    .unassign-inventory-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .mapped-requests, .unmapped-requests {
      height: 100%;
    }

    @media (max-width: 960px) {
      .prism-message {
        .prism-message__body {
          .prism-message__text {
            margin-right: 2%;
          }
        }
      }
    }

    @media (max-width: 959px) {
      .lane-management-request-board-wrapper {
        .mr-lg-2 {
          margin-right: 0.5%;
        }
      }
    }
  }

 /** responsive for request board **/
  @media only screen and (max-width: 1920px) and (max-height: 369px) {
     .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
        height: 17rem !important;
    }
  }
  @media  only screen and (max-width: 1142px) and (min-height: 410px) {
  .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
      height: calc(100vh - 8.3rem) !important;
    }
  }

  @media  only screen and (max-width: 1142px) and (max-height: 409px) {
    .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
       height:  17rem !important;
    }
  }

  @media  only screen and (max-width: 679px) and (min-height: 521px) {
      .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
        height: calc(100vh - 11.1rem) !important;
    }
  }
  @media  only screen and (max-width: 679px) and (max-height: 521px) {
    .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
        height:  21.5rem !important;
        }
  }

  @media only screen and (max-width: 593px) and (min-height: 480px){
    .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
      height: calc(100vh - 11rem) !important;
    }
  }
    @media only screen and (max-width: 593px) and (max-height: 480px){
       .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
         height: 21rem !important;
       }
    }
    @media only screen and (max-width: 500px) and (max-height: 500px){
        .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
            height: 21rem !important;
        }
            }
   @media  only screen and (max-width: 477px) {
        .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
            height: calc(100vh - 10rem) !important;
        }
    }
    @media  only screen and (max-width: 477px) and (max-height: 560px) {
        .lane-management-wrapper:has(.lane-management-request-board-wrapper) {
            height: 26rem !important;
        }
    }

    /** reponsive for lane management default  **/
    @media  only screen and (max-width: 19202px) and (max-height: 430px) {
      .lane-management-wrapper:has(.lane-management-detail-container) {
            height: 18rem !important;
        }
    }
    @media  only screen and (max-width: 1142px) and (min-height: 430px) {
      .lane-management-wrapper:has(.lane-management-detail-container) {
          height: calc(100vh - 8.3rem) !important;
        }
    }
    @media  only screen and (max-width: 1142px) and (max-height: 430px) {
      .lane-management-wrapper:has(.lane-management-detail-container) {
            height: 18rem !important;
        }
    }
   @media  only screen and (max-width: 700px) and (min-width: 680px) and (min-height: 430px){
       .lane-management-wrapper:has(.lane-management-detail-container) {
            height: calc(100vh - 10.5rem) !important;
            .lane-management-detail-container {
                height: 60%;
            }
          }
        }
    @media  only screen and (max-width: 700px) and (min-width: 680px) and (max-height: 430px){
       .lane-management-wrapper:has(.lane-management-detail-container) {
            height: 18rem !important;
            .lane-management-detail-container {
                height: 60%;
            }
          }
        }
    @media  only screen and (max-width: 679px) and (min-height: 400px){
     .lane-management-wrapper:has(.lane-management-detail-container) {
          height: calc(100vh - 11.1rem) !important;
          .lane-management-detail-container {
            height: 60%;
          }
        }
      }
       @media  only screen and (max-width: 679px) and (max-height: 400px){
           .lane-management-wrapper:has(.lane-management-detail-container) {
                height: 22rem !important;
                .lane-management-detail-container {
                  height: 60%;
                }
              }
            }
      @media  only screen and (max-width: 478px) and (min-height: 670px){
        .lane-management-wrapper:has(.lane-management-detail-container) {
              height: calc(100vh - 11.1rem) !important;
              .lane-management-detail-container {
                height: 60%;
              }
            }
          }
      @media  only screen and (max-width: 478px) and (max-height: 670px){
       .lane-management-wrapper:has(.lane-management-detail-container) {
            height: 29rem!important;
            .lane-management-detail-container {
              height: 60%;
            }
          }
        }

}