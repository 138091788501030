@import './../../../app-variable.scss';

.lm-global-spinner {
    &.mui-loading-indicator {
        background: rgba(255, 255, 255, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        z-index: 11000;

        .mui-loading-indicator__content {
            position: relative;
            z-index: 11000;
            border-radius: 3px;
            text-align: center;
            background-color: var(--blue-dark);
            color: var(--white);
            -webkit-box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
            box-shadow: 0 0 17px -4px rgba(0, 0, 0, 0.75);
            font-size: $app-font-size;
            padding: 10px 10px 2px 10px;
        }
        .mui-shim {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}
