@import '../../app-variable.scss';

.remaining-inventory-list-wrapper {
    height: calc(100vh - 165px);
    overflow-y: auto;
    .advanced-filters {
        .slide-out-content {
            width: 420px;
        }
    }
    @media only screen and (max-width: 420px) {
        .advanced-filters .text-area__textarea {
            width: 317px;
        }
    }
    @media  only screen and (max-width: 1000px) {
        .remaining-vehicle-list {
            font-size: $app-font-size-small-screen;
            .chip {
                font-size: $app-font-size-small-screen;
                min-width: 1rem;
                min-height: 1.2rem;
                height: 1.2rem;
            }
            .vehicle-title {
                font-size: calc($app-font-size-small-screen + 2px)
            }
            .vehicle-disposition-code {
                font-size: 17px;
            }
        }
    }

    .advanced-filters {
        .slide-out-content {
            .slide-out-body {
                max-height: calc(100% - 7rem) !important;
            }
        }
    }
}