@import '../../app-variable.scss';

.prism-overridden {
    .assign-inventory-search-bar {
        $dropdown-width: 80px;
        $dropdown-height: 32px;
        $dropdown-text-padding: 0.4rem 0.8rem !important;

        background-color: var(--ash);

        .assign-inventory-search-bar-label {
            font-size: $app-font-size;
            color: var(--navy);
            align-items: center;
            display: flex;

            &.show-by-label {
                width: 60px
            }
        }

        .assign-inventory-search-bar-input-group {
            gap: 6px !important;

            .prism-select {
                height: $dropdown-height;
                flex-grow: 0;

                .prism-select__option {
                    background-size: 0;
                    padding: $dropdown-text-padding;
                }

                .prism-select__menu-open {
                    padding: 0;
                }

                .prism-select__label {
                    font-size: $app-font-size;
                    padding-right: 0;
                }

                .prism-select__toggle {
                    background-size: $app-font-size;
                }

                .prism-select__option {
                    font-size: $app-font-size;
                }

                .prism-select__toggle {
                    font-size: $app-font-size;
                }

                .prism-select__menu-open {
                    margin-top: -8px;
                    max-height: 485px;
                    overflow-y: auto;
                }
            }

            .prism-select-md {
                width: $dropdown-width;
            }

            .form-control {
                &:focus {
                    box-shadow: none !important;
                }
            }

            &.show-by-input-group {
                .prism-select-md {
                    width: 150px;
                }
                .form-control {
                    &[type=text] {
                        width: 40%;
                    }
                }
            }

            .visible-button {
                visibility: visible;
            }
            .invisible-button {
                visibility: hidden;
            }
            .next-available-btn {
                white-space:nowrap
            }
        }
    }


    @media (max-width: 679px) {
        .assign-inventory-search-bar {
            .row {
                padding-left: 0 !important;
            }
        }
    }

}
