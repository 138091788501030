@import '../../app-variable.scss';

//Override css from prism lib for datepicker
.search-history-form {
  .search-history-form-row {
    .react-datepicker {
      font-size: 1em;
    }
    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__day-name, .react-datepicker__day {
      width: 1.9em;
      line-height: 1.9em;
      margin: 0.166em;
    }
    .picker .react-datepicker__day-name {
      color: inherit;
      font-size: $app-font-size;
      font-weight: 700;
      height: 2.2rem;
    }
    .picker .react-datepicker__header .navigation-button {
      width: 2.4rem;
      font-size: 1rem;
    }
    .picker .react-datepicker__header .current-month {
      display: inline-flex;
      flex-grow: 1;
      font-weight: 700;
      font-size: $app-font-size;
    }
    .picker .react-datepicker__month {
      padding: 0.9rem;
    }
    .picker .react-datepicker__header .picker-header-navigation {
      padding: 0;
    }
    .react-datepicker__current-month {
      font-size: 1em;
    }
    .react-datepicker__navigation {
      top: 1em;
      line-height: 1.7em;
      border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #ccc;
      left: 1em;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ccc;
      right: 1em;
    }
    .picker .react-datepicker__day:before {
      top: 0.4rem;
      left: 0.1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    .picker .react-datepicker__day {
      position: relative;
      z-index: 1;
      background: none;
      font-size: $app-font-size;
      height: 2.2rem;
    }
    .picker .react-datepicker__header .picker-header-navigation {
      display: flex;
    }
  }
}
