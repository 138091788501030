html:has(.vehicle-vcf-table) {
    overflow-y: hidden;
}

.vehicle-vcf-table {

    .slide-out-content {
        max-width: 90%;
    }

    .slide-out-body {
        width: 100%;
        overflow-x: auto !important;

        table {
            min-width: 600px;
        }
    }
}

