@import '../../app-variable.scss';

.prism-overridden {
  .assign-vehicle-list-wrapper {
    @media only screen and (max-width: 1175px) {
      .previous-lane-run {
        max-width: 125px !important;
      }
    }
  }
}