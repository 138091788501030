.sale-overview-wrapper {
    height: calc(100vh - 165px);

    .sale-overview {
        @media (max-width: 479px) {
            .sale-overview-items {
                max-height: calc(100vh - 22rem);
                overflow-y: auto;
            }
        }
        @media (min-width: 479px) {
            .sale-overview-items {
                max-height: calc(100vh - 315px);
                overflow-y: auto;
            }
        }

        .row {
            margin: 0;
        }
    }
}

@media (max-width: 680px) {
    .sale-overview-wrapper {
        height: calc(100vh - 140px);
    }
}

@media (max-height: 400px){
    .sale-overview-wrapper {
        height: 280px;
    }
}
