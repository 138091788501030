@import '../../../app-variable.scss';

.prism-overridden {
    .table-pagination {
        font-size: $app-font-size;
        .pagination-buttons {
            border-bottom: none;
            td {
                border-bottom: none;
            }
            .pagination-button {
                width: 40px;
                height: 40px;
                margin-left: 0.5px;
                margin-right: 0.5px;
                &:focus {
                    box-shadow: none;
                    background-color: transparent;
                }
                &.activated {
                    color: var(--white);
                    background-color: var(--navy);
                }
                .go-to-last-icon {
                    transform: rotate(180deg);
                }
            }
        }
        .pagination-page-details {
            color: var(--slate);
            border-top: none;
            td {
                border-top: none;
            }
        }
    }
}