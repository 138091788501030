@import '../../../app-variable.scss';

.prism-overridden {
    .sale-picker-container {
        $dropdown-width: 80px;
        $dropdown-height: 32px;
        $dropdown-text-padding: 0.4rem 0.8rem !important;

        background-color: var(--ash);

        .sale-picker-group {
            gap: 6px !important;

            .prism-select {
                height: $dropdown-height;
                flex-grow: 0;

                .prism-select__option {
                    background-size: 0;
                    padding: $dropdown-text-padding;
                }

                .prism-select__menu-open {
                    padding: 0;
                }

                .prism-select__label {
                    font-size: $app-font-size;
                    padding-right: 0;
                }

                .prism-select__toggle {
                    background-size: $app-font-size;
                }

                .prism-select__option {
                    font-size: $app-font-size;
                }

                .prism-select__toggle {
                    font-size: $app-font-size;
                }

                .prism-select__menu-open {
                    margin-top: -8px;
                    max-height: 485px;
                    overflow-y: auto;
                }
            }

            .prism-select-md {
                width: $dropdown-width;
            }

            .form-control:focus {
                box-shadow: none !important;
            }

            .sale-overview-header-label {
                font-size: $app-font-size;
                color: var(--navy);
                align-items: center;
                display: flex;
            }
        }
    }
}
