@import '../../../app-variable';

html:has(.assign-inventory-pre-registration) {
  overflow-y: hidden;
}

.assign-inventory-pre-registration {
  $dropdown-width-lg: 160px;
  $dropdown-width-sm: 100px;
  $dropdown-height: 32px;
  $dropdown-text-padding: 0.4rem 0.4rem !important;

  .column {
    gap: 13px !important;
  }

  .slide-out-content {
    min-width: 420px;
    h1 {
      margin-right: 7%;
    }

  }

  .slide-out-body {
    max-height: calc(100% - 6rem) !important;
    padding-right: 1rem;

    .radio-dropdown {
      min-width: 125px;
    }

    .trans-dropdown {
      padding-right: 0 !important;
    }

    .trans-dropdown .prism-select {
      min-width: 120px;
    }

    .engine-dropdown {
      min-width: 135px;
    }

    .trans-dropdown, .radio-dropdown {
      max-width: 180px;
    }

    .announcements-input {
      min-width: 100px;
    }

    .body-code-wrapper .prism-select button {
      min-width: 8rem;
    }

    .engine-dropdown, .body-code-wrapper {
      button {
        padding-right: 1.2rem;
      }
    }

    .input-model-group {
      gap: .2rem;
    }

    @media only screen and (max-width: 1650px) {
      .year-wrapper {
        .prism-input {
          width: 50px;
        }
      }
    }
    @media only screen and (max-width: 1370px) {
      .make-wraper {
         max-width: 31%;
      }
    }
    @media only screen and (max-width: 1120px) {
      .year-wrapper {
        margin-right: 5% !important;
      }
      .make-wraper {
        max-width: 28%;
      }
    }
    @media only screen and (max-width: 679px) {
      .year-wrapper {
        margin-right: 0 !important;
      }
      .make-wraper {
        max-width: 100%;
      }
    }
    @media only screen and (max-width: 1005px) {
      .form-control {
        font-size: 12px !important;
      }
    }
    @media only screen and (max-width: 1000px) {
      .search-seller-wrapper {
        padding-left: 3px !important;
        .btn-outline {
        }
      }
    }
    @media only screen and (max-width: 1140px){
      .disp-wrapper {
        padding-right: 3px;
      }
    }
    @media (max-width: 480px) {
      .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
      }
    }

  }
  .slide-out-content {
    min-width: 420px;
    padding-right: 0 !important;
  }
  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    .slide-out-content {
      width: 45%;
    }
    .slide-out-content .slide-out-body{
      max-height: calc(100% - 7.3rem) !important;
    }
    .trim-select {
      padding-left: 12px;
    }
  }

  @media only screen and (max-width: 1720px) and (max-height: 1080px) {
    .slide-out-content {
      width: 45%;
    }
  }

  @media only screen and (max-width: 1620px) and (max-height: 800px) {
    .slide-out-content {
      width: 50%;
    }
  }

  @media only screen and (max-width: 1550px) and (max-height: 800px) {
    .slide-out-content {
      width: 52%;
    }
  }

  @media only screen and (max-width: 1168px) and (max-height: 800px) {
    .slide-out-content {
      width: 67%;
    }
    .slide-out-content .slide-out-body{
      height: 87%;
    }
    .column{
      gap: 8px !important;
    }
    .trim-select {
      padding-left: 20px;
    }
  }
  @media only screen and (max-width: 1168px) and (max-height: 1000px) {
    .slide-out-content {
      width: 67%;
    }
  }
  @media only screen and (max-width: 1060px) {
    .cruise-wrapper{
      margin-right: 1%;
    }
  }
  @media only screen and (max-width: 1005px) {
    .option-on-top{
      .prism-select-md {
        width: auto;
      }
    }
    .bu-wrapper{
      .prism-select-md {
        width: auto;
        margin-right: 0.4rem
      }
    }

  }
  @media only screen and (max-width: 1005px) {
    .body-code-wrapper {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .cruise-wrapper{
      margin-right: 2% !important;
    }
  }

  @media only screen and (max-width: 679px) {
    .slide-out-content {
      width: calc(100% - 4rem);
    }

    .item-row {
      margin-left: 0 !important;
    }

    .option-on-top{
      padding-left: 4%;
      padding-right: 0px !important;
    }
  }

  @media only screen and (max-width: 657px) {
    .option-on-top{
      padding-right: 0 !important;
      padding-left: 5%;
    }
  }

  @media only screen and (max-width: 499px) {
    .slide-out-body, label.col-form-label.col.font-size-sm {
      font-size: 12px;
    }

    button {
      font-size: 12px !important;
    }

    .prism-select__label {
      font-size: 12px !important;
    }

    .slide-out-content {
      min-width: 100%;
    }

    .assign-inventory-pre-registration .option-on-top {
      padding-left: 4%;
    }

    .floor-input {
      padding-right: 0 !important;
    }

    .option-on-top{
      padding-right: 0 !important;
      padding-left: 0;
      margin-left: 0 !important;

      .prism-select-md {
        width: auto;
        max-width: 13rem
      }
    }

    .subseries {
      width: 5.3rem;
    }

    .last-item-row {
      gap: 15px;
    }
  }

  @media only screen and (max-height: 800px) {
    .option-on-top{
      .prism-select{
        .prism-select__menu-open{
          bottom: 100%;
          top: auto
        }
      }
    }
  }

  .pre-registration-button-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 2.3rem!important;

  }

  .group-btn-right {
    right: 0;
  }

  .visible-dropdown {
    visibility: visible;
  }
  .invisible-dropdown {
    visibility: hidden;
  }

  .vin-decode-wrapper {
    .trim-select-dropdown {
      max-width: 150px;
      min-width: 100px;
      width: 7rem;
    }

    @media only screen and (max-width: 1630px) {
      .trim-select {
        margin-left: 4%;
      }
    }
    @media only screen and (max-width: 1350px) {
      .trim-select {
        margin-left: 6%;
      }
    }
    @media only screen and (max-width: 1130px) {
      .trim-select {
        margin-left: 8%;
      }
    }
    @media only screen and (max-width: 1005px) {
      .btn:not(.actions .actions-action) {
        font-size: 12px !important;
      }
    }
    @media only screen and (max-width: 679px) {
      .pl-2 {
        padding-left: 0.4rem !important;
      }
    }
  }

  .prism-select {
    flex-grow: 0;

    .prism-select__option {
      background-size: 0;
      padding: $dropdown-text-padding;
    }

    .prism-select-md {
      height: $dropdown-height;
    }

    .prism-select__menu-open {
      padding: 0;
    }

    .prism-select__label {
      font-size: $app-font-size;
    }

    .prism-select__toggle {
      background-size: $app-font-size;
      height: $dropdown-height;
    }

    .prism-select__option {
      font-size: $app-font-size;
    }

    .prism-select__toggle {
      font-size: $app-font-size;
    }

    .prism-select__menu-open {
      margin-top: -8px;
    }
    .prism-select__menu-open {
      margin-top: -8px;
      max-height: 165px;
      overflow-y: auto;
      z-index: 999;
    }
  }

  .vin-decode-error .prism-message {
    margin: 0.2rem 0 0 0 !important;
    padding: 0 !important;
    background: inherit;
  }

  .vin-decode-error .prism-message .prism-message__text {
    line-height: normal;
    margin-right: 15px;
  }

  .vin-decode-error .prism-message .prism-icon-svg {
    margin-top: 0 !important;
  }

  .error-message{
    margin: 0.2rem 0 0 0.2rem !important
  }

  .disabled-background-color {
    background-color: #babcbe !important;
  }

  @media only screen and (max-width: 1005px) {
    .prism-select__label {
      font-size: 12px !important;
    }
  }
}