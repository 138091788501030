@import '../../app-variable';
.prism-overridden {
  .lane-management-change-run {
    padding: 0 !important;
    .slide-out-content h1 {
      margin-right: 5% !important;
    }

    .slide-out-body {
      max-height: calc(100% - 6rem) !important;
    }

    .prism-radio>label {
      margin-top: 3px;
      margin-bottom: 3px
    }

    input.form-text.prism-input.form-control {
      margin-top: 3px;
    }

    .search-seller-btn {
      align-items: center;
      display: flex;
      margin-top: 1.6em;
    }

    .lane-management-change-run-footer {
      background-color: white;
      bottom: 0;

      .btn-outline {
        margin-right: 0 !important;
      }

      @media (max-width: 480px) {
        .flex-sm-row-reverse {
          flex-direction: row-reverse !important;
        }
      }
    }

    .control-buttons {
      width: 100%;
      display: flex;
      padding-right: 0.1rem !important;
    }

    .col-form-label {
      font-size: $app-font-size;
    }

    .col-form-label label, .col-form-label {
      font-size: $app-font-size !important;;
      color: #6a6a6a;
      margin-top: 0px !important;
    }

    input[type=text] {
      font-size: $app-font-size !important;
      padding: .2rem .4rem !important;
      height: $app-from-control-height
    }

    .prism-radio input {
      height: 16px !important;
      min-width: 16px !important;
      width: 16px !important;
    }

    .mx-2.prism-message--danger {
      margin: 0 0 0.8rem 0 !important;

      .prism-message__text {
        line-height: 1.2rem;
      }
    }

    .mx-2.prism-message--success {
      margin: 0 0 0.8rem 0 !important;
    }

    .col-md-1 {
      flex: 0 0 5%;
      max-width: 5%;
    }

    .add-symbol {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
